@import '~carbon-components/scss/globals/scss/vendor/@carbon/elements/scss/themes/theme-maps';
$carbon--theme: $carbon--theme--g10;

$feature-flags: (
  components-x: true,
  ui-shell: true,
);
@import '~carbon-components/scss/globals/scss/styles.scss';

h1 {
  @include type-style('display-04');
}

h2 {
  @include type-style('expressive-heading-04');
  margin-bottom: $layout-02;
}

p {
  @include type-style('expressive-paragraph-01');
  margin-bottom: $spacing-01;
}

#main {

}

.demo-button {
  margin-top: $spacing-04;
}

.hero {
  position: relative;
  min-height: 80vh;
  background-image: linear-gradient($overlay-01, $overlay-01), url("./img/hero-background-image.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  &-text {
    padding: $layout-03;
    @media (max-width: 320px) {
      padding: $layout-01;
    }
    background-color: $interactive-02;
    width: 100%;
    position: absolute;
    bottom: 0;
    color: $text-04;
  }
}

.faq {
  padding: $layout-03;
  @media (max-width: 320px) {
    padding: $layout-01;
  }

  p {
    @include type-style('body-short-02');
  }
}
